<template>
  <div v-if="bannedOrg" class="error_text">
    <p>В сборе могут участвовать только федеральные организации</p>
  </div>
  <div v-else class="applicants">
    <v-btn v-if="fakeUserAdmin" class="router_btn" @click="returnToAdmin">&#8592;</v-btn>
    <p v-if="getFakeUser" style="color: #EB722E; margin-bottom: 8px;">{{ getFakeUser.name }}</p>
    <!--    @row-action="clickOnRow($event)"-->
    <div style="display: flex; flex-direction: row">
      <h2 class="applicants__header">Претенденты на стипендии</h2>
      <router-link :to="{ path: '/profile' }">
        <!-- :disabled="!thereAreContacts":style="!thereAreContacts ? 'color: #89A0B0; border: 1px solid #89A0B0; cursor: unset': ''"-->
        <button class="usual-btn usual-btn_bold" style="margin: 0 26px 17px auto">
          Ответственные исполнители
        </button>
      </router-link>
      <button
        class="usual-btn usual-btn_bold"
        style="margin: 0 22px 17px 0"
        @click="orgDocDialog = true"
        :disabled="!thereAreContacts"
        :style="
          !thereAreContacts ? 'color: #89A0B0; border: 1px solid #89A0B0; cursor: unset' : ''
        ">
        Документы организации
      </button>
      <button
        class="usual-btn usual-btn_bold"
        style="margin: 0 0 17px 0"
        @click="addRow"
        :disabled="!thereAreContacts"
        :style="
          !thereAreContacts ? 'color: #89A0B0; border: 1px solid #89A0B0; cursor: unset' : ''
        ">
        Добавить претендента
      </button>
    </div>

    <v-dialog v-model="deleteConfirmDialog" width="375">
      <v-card>
        <v-card-title>Подтверждение удаления строки</v-card-title>
        <v-card-actions style="justify-content: center">
          <v-btn class="dialog-btn" @click="deleteRow">Да</v-btn>
          <v-btn class="dialog-btn" @click="deleteConfirmDialog = false">Нет</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="orgDocDialog" width="480px">
      <v-card class="applicantsOrgDocs">
        <h2 class="applicantsOrgDocs__header">Документы организации</h2>
        <img
          @click="orgDocDialog = false"
          class="dialog__exit"
          src="@/assets/icons/dialog-exit.png"
          alt="exit"/>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">Устав организации</p>
            <p
              class="document-file"
              v-html="ustavFileName"
              v-if="ustavComputed !== 'Файл не загружен'"
              @click="getFile(ustavObject.uuid, ustavObject.fileNameOrig, ustavObject.fileExt)"></p>
            <p class="document-file document-file_empty" v-else>Не загружено</p>
          </div>
          <v-file-input
            prepend-icon="mdi-folder-open-outline" v-model="ustavFile"
            accept="application/pdf"
            :style="
              ustavFileStyleDependsOnKonvert || docs.row.konvertConvert ? 'visibility: hidden' : ''
            "
            placeholder="Выберите файл"
            @change="ustavPost"></v-file-input>
          <img
            :style="
              ustavComputed !== 'Файл загружен' || docs.row.konvertConvert
                ? 'visibility: hidden'
                : ''
            "
            src="@/assets/icons/delete-round.svg"
            alt="delete"
            class="document-delete"
            @click="deleteOrgFile(ustavObject.uuid, 'USTAV_VALIEV')"/>
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">Выписка из ЕГРЮЛ</p>
            <p
              class="document-file"
              v-html="egrulFileName"
              v-if="egrulComputed !== 'Файл не загружен'"
              @click="getFile(egrulObject.uuid, egrulObject.fileNameOrig, egrulObject.fileExt)"></p>
            <p class="document-file document-file_empty" v-else>Не загружено</p>
          </div>
          <v-file-input
            prepend-icon="mdi-folder-open-outline"
            v-model="egrulFile"
            accept="application/pdf"
            :style="
              egrulFileStyleDependsOnKonvert || docs.row.konvertConvert ? 'visibility: hidden' : ''
            "
            placeholder="Выберите файл"
            @change="egrulPost"></v-file-input>
          <img
            :style="
              egrulComputed !== 'Файл загружен' || docs.row.konvertConvert
                ? 'visibility: hidden'
                : ''
            "
            src="@/assets/icons/delete-round.svg"
            alt="delete"
            class="document-delete"
            @click="deleteOrgFile(egrulObject.uuid, 'EGRUL_VALIEV')"/>
        </div>
        <button class="usual-btn" style="margin: 23px auto 26px 25px" @click="orgDocDialog = false">
          Закрыть
        </button>
      </v-card>
    </v-dialog>

    <!--                    applicantDocsDialog                     -->

    <v-dialog v-model="applicantDocsDialog" width="740px">
      <v-card
        class="applicantsOrgDocs"
        :style="docs.oldFioRow ? 'min-height: 830px' : 'min-height: 760px'">
        <h2 class="applicantsOrgDocs__header">Заявка претендента</h2>
        <img
          @click="applicantDocsDialog = false"
          class="dialog__exit"
          src="@/assets/icons/dialog-exit.png"
          alt="exit"/>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: column">
            <p class="document-name" style="font-weight: 500">{{ docs.fio }}</p>
            <p
              class="document-file document-file_empty"
              style="color: #89a0b0; border-bottom: none">
              {{ docs.info }}
            </p>
          </div>
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">Опись</p>
          </div>
          <img src="@/assets/icons/print.svg" @click="getInventory(docs.rowID)" alt="print" class="document-print">
          <!--    визуальная заглушка     -->
          <v-file-input
            prepend-icon="mdi-folder-open-outline"
            accept="application/pdf"
            placeholder="Выберите файл"
            style="visibility: hidden"
          ></v-file-input>
          <!--    визуальная заглушка     -->
          <img
            style="visibility: hidden"
            src="@/assets/icons/delete-round.svg"
            alt="delete"
            class="document-delete"/>
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">Лист проверки</p>
          </div>
          <img src="@/assets/icons/print.svg" alt="print" @click="getCheckList(docs.rowID)" class="document-print">
          <!--    визуальная заглушка     -->
          <v-file-input
            prepend-icon="mdi-folder-open-outline"
            accept="application/pdf"
            placeholder="Выберите файл"
            style="visibility: hidden"></v-file-input>
          <!--    визуальная заглушка     -->
          <img
            style="visibility: hidden"
            src="@/assets/icons/delete-round.svg"
            alt="delete"
            class="document-delete"/>
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">Характеристика-рекомендация претендента</p>
            <p
              class="document-file"
              v-html="character1Computed"
              v-if="character1Computed !== 'Файл не загружен'"
              @click="
                getFile(docs.character1.uuid, docs.character1.fileNameOrig, docs.character1.fileExt)
              "></p>
            <p class="document-file document-file_empty" v-else>Не загружено</p>
          </div>
          <img
            src="@/assets/icons/print.svg"
            alt="print"
            @click="getAppendix1(docs.rowID)"
            class="document-print"/>
          <v-file-input
            prepend-icon="mdi-folder-open-outline"
            v-model="character1File"
            :style="
              !applicantDocsDisabled || docs.character1 || docs.row.konvertConvert
                ? 'visibility: hidden'
                : ''
            "
            accept="application/pdf"
            placeholder="Выберите файл"
            @change="filePost('FILE_CHARACTER_1', character1File)"></v-file-input>
          <img
            :style="
              character1Computed === 'Файл не загружен' || docs.row.konvertConvert
                ? 'visibility: hidden'
                : ''
            "
            src="@/assets/icons/delete-round.svg"
            alt="delete"
            class="document-delete"
            @click="deleteFile('FILE_CHARACTER_1')"/>
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">Приложение к характеристике-рекомендации</p>
            <p
              class="document-file"
              v-html="character2Computed"
              v-if="character2Computed !== 'Файл не загружен'"
              @click="
                getFile(docs.character2.uuid, docs.character2.fileNameOrig, docs.character2.fileExt)
              "></p>
            <p class="document-file document-file_empty" v-else>Не загружено</p>
          </div>
          <img
            src="@/assets/icons/print.svg"
            alt="print"
            @click="getAppendix2(docs.rowID)"
            class="document-print"/>
          <v-file-input
            prepend-icon="mdi-folder-open-outline"
            v-model="character2File"
            :style="
              !applicantDocsDisabled || docs.character2 || docs.row.konvertConvert
                ? 'visibility: hidden'
                : ''
            "
            accept="application/pdf"
            placeholder="Выберите файл"
            @change="filePost('FILE_CHARACTER_2', character2File)"></v-file-input>
          <img
            :style="
              character2Computed === 'Файл не загружен' || docs.row.konvertConvert
                ? 'visibility: hidden'
                : ''
            "
            src="@/assets/icons/delete-round.svg"
            alt="delete"
            class="document-delete"
            @click="deleteFile('FILE_CHARACTER_2')"/>
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">
              Согласие на обработку, хранение и передачу персональных данных
            </p>
            <p
              class="document-file"
              v-html="approvalComputed"
              v-if="approvalComputed !== 'Файл не загружен'"
              @click="
                getFile(docs.approval.uuid, docs.approval.fileNameOrig, docs.approval.fileExt)
              "></p>
            <p class="document-file document-file_empty" v-else>Не загружено</p>
          </div>
          <img
            src="@/assets/icons/print.svg"
            alt="print"
            style="visibility: hidden"
            class="document-print"/>
          <v-file-input
            prepend-icon="mdi-folder-open-outline"
            v-model="approvalFile"
            :style="
              !applicantDocsDisabled || docs.approval || docs.row.konvertConvert
                ? 'visibility: hidden'
                : ''
            "
            accept="application/pdf"
            placeholder="Выберите файл"
            @change="filePost('FILE_SOGL', approvalFile)"></v-file-input>
          <img
            :style="
              approvalComputed === 'Файл не загружен' || docs.row.konvertConvert
                ? 'visibility: hidden'
                : ''
            "
            src="@/assets/icons/delete-round.svg"
            alt="delete"
            class="document-delete"
            @click="deleteFile('FILE_SOGL')"/>
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">Выписка из решения учёного (научного, научно-технического) совета</p>
            <p
              class="document-file"
              v-html="counsilDecisionComputed"
              v-if="counsilDecisionComputed !== 'Файл не загружен'"
              @click="
                getFile(
                  docs.councilDecision.uuid,
                  docs.councilDecision.fileNameOrig,
                  docs.councilDecision.fileExt,
                )
              "></p>
            <p class="document-file document-file_empty" v-else>Не загружено</p>
          </div>
          <img
            src="@/assets/icons/print.svg"
            alt="print"
            style="visibility: hidden"
            class="document-print"/>
          <v-file-input
            prepend-icon="mdi-folder-open-outline"
            v-model="councilDecisionFile"
            :style="
              !applicantDocsDisabled || docs.councilDecision || docs.row.konvertConvert
                ? 'visibility: hidden'
                : ''
            "
            accept="application/pdf"
            placeholder="Выберите файл"
            @change="filePost('FILE_SOVET', councilDecisionFile)"></v-file-input>
          <img
            :style="
              counsilDecisionComputed === 'Файл не загружен' || docs.row.konvertConvert
                ? 'visibility: hidden'
                : ''
            "
            src="@/assets/icons/delete-round.svg"
            alt="delete"
            class="document-delete"
            @click="deleteFile('FILE_SOVET')"/>
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">Сведения об успеваемости</p>
            <p
              class="document-file"
              v-html="academicPerformanceComputed"
              v-if="academicPerformanceComputed !== 'Файл не загружен'"
              @click="
                getFile(
                  docs.academicPerformance.uuid,
                  docs.academicPerformance.fileNameOrig,
                  docs.academicPerformance.fileExt,
                )
              "></p>
            <p class="document-file document-file_empty" v-else>Не загружено</p>
          </div>
          <img
            src="@/assets/icons/print.svg"
            alt="print"
            style="visibility: hidden"
            class="document-print"/>
          <v-file-input
            prepend-icon="mdi-folder-open-outline"
            v-model="academicPerformanceFile"
            :style="
              !applicantDocsDisabled || docs.academicPerformance || docs.row.konvertConvert
                ? 'visibility: hidden'
                : ''
            "
            accept="application/pdf"
            placeholder="Выберите файл"
            @change="filePost('FILE_USPEH', academicPerformanceFile)"
          ></v-file-input>
          <img
            :style="
              academicPerformanceComputed === 'Файл не загружен' || docs.row.konvertConvert
                ? 'visibility: hidden'
                : ''
            "
            src="@/assets/icons/delete-round.svg"
            alt="delete"
            class="document-delete"
            @click="deleteFile('FILE_USPEH')"/>
        </div>
        <div class="applicantsOrgDocs-div" v-if="docs.row.mainCourse == 1">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">Копия диплома предыдущего уровня подготовки</p>
            <p
              class="document-file"
              v-html="prevDiplomaCopyComputed"
              v-if="prevDiplomaCopyComputed !== 'Файл не загружен'"
              @click="
                getFile(
                  docs.prevDiplomaCopy.uuid,
                  docs.prevDiplomaCopy.fileNameOrig,
                  docs.prevDiplomaCopy.fileExt,
                )
              "></p>
            <p class="document-file document-file_empty" v-else>Не загружено</p>
          </div>
          <img
            src="@/assets/icons/print.svg"
            alt="print"
            style="visibility: hidden"
            class="document-print"
          />
          <v-file-input
            prepend-icon="mdi-folder-open-outline"
            v-model="prevDiplomaCopyFile"
            :style="
              !applicantDocsDisabled || docs.prevDiplomaCopy || docs.row.konvertConvert
                ? 'visibility: hidden'
                : ''
            "
            accept="application/pdf"
            placeholder="Выберите файл"
            @change="filePost('FILE_COPY_DIPLOM', prevDiplomaCopyFile)"></v-file-input>
          <img
            :style="
              prevDiplomaCopyComputed === 'Файл не загружен' || docs.row.konvertConvert
                ? 'visibility: hidden'
                : ''
            "
            src="@/assets/icons/delete-round.svg"
            alt="delete"
            class="document-delete"
            @click="deleteFile('FILE_COPY_DIPLOM')"/>
        </div>
        <div class="applicantsOrgDocs-div" v-if="docs.oldFioRow">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">Документ, подтверждающий смену ФИО</p>
            <p
              class="document-file"
              v-html="oldFioComputed"
              v-if="oldFioComputed !== 'Файл не загружен'"
              @click="
                getFile(
                  docs.oldFioObject.uuid,
                  docs.oldFioObject.fileNameOrig,
                  docs.oldFioObject.fileExt,
                )
              "></p>
            <p class="document-file document-file_empty" v-else>Не загружено</p>
          </div>
          <!--заглушка для стилей-->
          <img
            src="@/assets/icons/print.svg"
            alt="print"
            style="visibility: hidden"
            class="document-print"/>
          <v-file-input
            prepend-icon="mdi-folder-open-outline"
            v-model="oldFioFile"
            :style="
              !applicantDocsDisabled || docs.oldFioObject || docs.row.konvertConvert
                ? 'visibility: hidden'
                : ''
            "
            accept="application/pdf"
            placeholder="Выберите файл"
            @change="filePost('FILE_OLD_FIO', oldFioFile)"></v-file-input>
          <img
            :style="
              oldFioComputed === 'Файл не загружен' || docs.row.konvertConvert
                ? 'visibility: hidden'
                : ''
            "
            src="@/assets/icons/delete-round.svg"
            alt="delete"
            class="document-delete"
            @click="deleteFile('FILE_OLD_FIO')"/>
        </div>
        <button
          class="usual-btn"
          style="margin: 23px auto 26px 25px"
          @click="applicantDocsDialog = false">
          Закрыть
        </button>
      </v-card>
    </v-dialog>

    <!--                    applicantDocsDialog                     -->

    <GridTable
      :data-rows="orgArray"
      :headers="tableHeaders.orgMassive"
      :headers-for-rows="tableHeaders.orgMassive"
      :num-cols="tableHeaders.countCells"
      :loading-data="loading"
      :borderTop="'none'"
      :error-text="!thereAreContacts ? 'Не указаны ответственные за ввод данных по сбору' : ''"
      style="background: #ffffff; box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06); border-radius: 4px">
      <template #fio="{ row }">
        <div style="display: flex; flex-direction: column">
          <span class="rowOrder">{{ row.rowOrder }}</span>
          <div class="colorLabel" :style="`background: ${row.color}`"></div>
        </div>
        <div
          style="display: flex; flex-direction: column; margin-left: 16px"
          @click="editRow(row.id)">
          <span>{{ row.mainLastName }}</span>
          <span>{{ `${row.mainFirstName} ${row.mainSecondName}` }}</span>
        </div>
      </template>
      <template #info="{ row }">
        <span>{{ `${row.mainEducationStatus.educationStatus} • ${row.mainFo.name}` }}</span>
        <span>{{ `${row.mainCourse} курс/год обучения` }}</span>
      </template>
      <template #spec="{ row }">
        <span style="font-weight: 600">{{ row.mainSpec.kodSpec }}</span>
        <span>{{ row.mainSpec.name }}</span>
      </template>
      <template #stpType="{ row }">
        <span>{{ row.mainStpType.name }}</span>
      </template>
      <template #docs="{ row }">
        <div class="block__wrapper" @click="applicantDocsDialogOpen(row)">
          <div class="applicant__docs"></div>
        </div>
      </template>
      <template #actions="{ row }">
        <!--        <button class="usual-btn" style="margin-right: 11px;" @click="editRow(row.id)">Открыть</button>-->
        <div v-if="row.konvertConvert" style="display: flex; flex-direction: row">
          <img src="@/assets/icons/konvert.svg" style="margin-right: 10px; align-self: baseline"/>
          <div style="display: flex; flex-direction: column">
            <span>{{ row.konvertConvert }}</span>
            <span class="konvertDate" v-if="row.konvertConvertDate">
              {{ dateFormat(row.konvertConvertDate.slice(0, 10)) }}
            </span>
          </div>
        </div>
        <div
          class="applicant__delete applicant__delete_disabled"
          v-else-if="row.blockFlBlock || row.adminAgreePatchAdminTbl5Agree"></div>
        <div class="applicant__delete" v-else @click="deleteConfirmFunc(row.id)"></div>
      </template>
    </GridTable>
  </div>
</template>

<script>
import axios from 'axios';
import {mapActions, mapGetters} from 'vuex'; //mapActions
import {GridTable} from '@frontenddevelopers/ined-components/src/lib';
import ApplicantsValievJson from '@/2024/components/applicants/json/ApplicantsValievJson';
import request from '@/services/request';

export default {
  name: 'ApplicantsValiev',
  components: {GridTable},
  data() {
    return {
      orgType: null,
      tableHeaders: [],
      orgArray: [],
      egrulAndUstavArray: [],
      deleteConfirmDialog: false,
      deleteRowID: null,
      orgDocDialog: false,
      applicantDocsDialog: false,
      contacts: [],
      loading: false,
      egrulFile: null,
      ustavFile: null,
      character1File: null,
      character2File: null,
      approvalFile: null,
      councilDecisionFile: null,
      academicPerformanceFile: null,
      prevDiplomaCopyFile: null,
      oldFioFile: null,
      egrulObject: null,
      ustavObject: null,
      konvert: false,
      docs: {
        row: {konvertConvert: null},
        rowID: null,
        fio: null,
        info: null,
        oldFioRow: false,
        approval: {},
        character1: {},
        character2: {},
        councilDecision: {},
        academicPerformance: {},
        prevDiplomaCopy: {},
        oldFioObject: {},
      }
    }
  },

  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      getUser: 'getUser',
      getFakeUser: 'getFakeUser',
      isManager: 'isManager',
    }),

    bannedOrg() {
      if (this.orgType == '52' || this.orgType == '53') {
        return true;
      } else {
        return false;
      }
    },

    egrulFileName() {
      return `${this.egrulObject?.fileNameOrig}.${this.egrulObject?.fileExt}`;
    },

    ustavFileName() {
      return `${this.ustavObject?.fileNameOrig}.${this.ustavObject?.fileExt}`;
    },

    fakeUserAdmin() {
      if (this.getFakeUser?.admin) {
        return true
      } else {
        return false
      }
    },

    thereAreContacts() {
      if (
        this.contacts.length !== 0 &&
        this.contacts.find((el) => el.stpContestNameses.find((el) => el.id === 9 || el.id === 10))
      ) {
        return true;
      } else {
        return false;
      }
    },

    ustavComputed() {
      if (this.ustavObject && this.konvert) {
        return 'Файл загружен, конверт зарегистрирован'
      } else if (this.ustavObject) {
        return 'Файл загружен'
      } else {
        return 'Файл не загружен'
      }
    },

    ustavFileStyleDependsOnKonvert() {
      if (!this.ustavObject && !this.konvert) {
        return false
      } else {
        return true
      }
    },

    egrulComputed() {
      if (this.egrulObject && this.konvert) {
        return 'Файл загружен, конверт зарегистрирован'
      } else if (this.egrulObject) {
        return 'Файл загружен'
      } else {
        return 'Файл не загружен'
      }
    },

    egrulFileStyleDependsOnKonvert() {
      if (!this.egrulObject && !this.konvert) {
        return false
      } else {
        return true
      }
    },

    character1Computed() {
      if (this.docs.character1) {
        return `${this.docs.character1.fileNameOrig}.${this.docs.character1.fileExt}`;
      } else {
        return 'Файл не загружен';
      }
    },

    character2Computed() {
      if (this.docs.character2) {
        return `${this.docs.character2.fileNameOrig}.${this.docs.character2.fileExt}`;
      } else {
        return 'Файл не загружен';
      }
    },

    approvalComputed() {
      if (this.docs.approval) {
        return `${this.docs.approval.fileNameOrig}.${this.docs.approval.fileExt}`;
      } else {
        return 'Файл не загружен';
      }
    },

    counsilDecisionComputed() {
      if (this.docs.councilDecision) {
        return `${this.docs.councilDecision.fileNameOrig}.${this.docs.councilDecision.fileExt}`;
      } else {
        return 'Файл не загружен';
      }
    },

    academicPerformanceComputed() {
      if (this.docs.academicPerformance) {
        return `${this.docs.academicPerformance.fileNameOrig}.${this.docs.academicPerformance.fileExt}`;
      } else {
        return 'Файл не загружен';
      }
    },

    prevDiplomaCopyComputed() {
      if (this.docs.prevDiplomaCopy) {
        return `${this.docs.prevDiplomaCopy.fileNameOrig}.${this.docs.prevDiplomaCopy.fileExt}`;
      } else {
        return 'Файл не загружен';
      }
    },

    oldFioComputed() {
      if (this.docs.oldFioObject) {
        return `${this.docs.oldFioObject.fileNameOrig}.${this.docs.oldFioObject.fileExt}`;
      } else {
        return 'Файл не загружен';
      }
    },

    applicantDocsDisabled() {
      if (
        this.docs.row?.blockFlBlock === true &&
        this.docs.row?.adminAgreePatchAdminTbl5Agree === null
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    this.getAll();
    this.getOrgType();
    this.getEgrulAndUstav();
    this.getContacts();
    this.tableHeaders = ApplicantsValievJson(this);
  },
  methods: {
    ...mapActions('keycloak', {clearFakeUser: 'clearFakeUser'}),
    async getAll() {
      this.loading = true;
      let uri = `/api/2024/valiev/get?orgId=${this.getUser.orgID}`;
      if (this.getFakeUser) {
        uri = `/api/2024/valiev/get?orgId=${this.getFakeUser.id}`;
      }
      try {
        const res = await axios.get(uri, {
          headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
        });
        if (res.status === 200 && res.data) {
          this.orgArray = res.data;
          for (let a = 0; a < this.orgArray.length; a++) {
            this.orgArray[a].rowOrder = a + 1;

            if (this.isManager) {
              if (
                this.orgArray[a].blockFlBlock === null ||
                this.orgArray[a].blockFlBlock === false
              ) {
                this.orgArray[a].color = '#F57F17';
              } else if (
                this.orgArray[a].blockFlBlock === true &&
                this.orgArray[a].adminAgreePatchAdminTbl5Agree === null
              ) {
                this.orgArray[a].color = '#9C27B0';
              } else if (
                this.orgArray[a].blockFlBlock === true &&
                this.orgArray[a].adminAgreePatchAdminTbl5Agree === true
              ) {
                this.orgArray[a].color = '#4CAF50';
              } else {
                this.orgArray[a].color = '#DC2627';
              }
            } else {
              if (
                this.orgArray[a].blockFlBlock === null ||
                this.orgArray[a].blockFlBlock === false
              ) {
                this.orgArray[a].color = '#F57F17';
              } else {
                this.orgArray[a].color = '#9C27B0';
              }
            }
          }

          if (this.orgArray.find((el) => el.konvertConvert)) {
            this.konvert = true;
          }
          this.loading = false;
          console.log('getAll успех');
        }
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },

    async getOrgType() {
      try {
        const res = await axios.get(
          `/api/cms/spr/org/byId/get?orgId=${
            this.getUser.orgID ? this.getUser.orgID : this.getFakeUser.id
          }`,
          {headers: {Authorization: `Bearer   ${this.getAuthData.token}`}},
        );
        if (res.status === 200 && res.data) {
          this.orgType = res.data.orgFounder.id;
          console.log(`getOrgType`, this.orgType);
          console.log('getOrgType успех');
        }
      } catch (e) {
        console.log(e);
      }
    },

    dateFormat(arg) {
      let x = arg.replace(/-/g, ".");
      let years = x.slice(0, 4);
      let days = x.slice(-2);
      let months = x.slice(4, -2);
      return `${days}${months}${years}`
    },

    async getEgrulAndUstav() {
      this.loading = true;
      let uri = `/api/2024/file/scan/egrulUstav/valiev?orgId=${this.getUser.orgID}`;
      if (this.getFakeUser) {
        uri = `/api/2024/file/scan/egrulUstav/valiev?orgId=${this.getFakeUser.id}`;
      }
      try {
        const res = await axios.get(uri, {
          headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
        });
        if (res.status === 200 && res.data) {
          this.egrulAndUstavArray = res.data;
          this.egrulObject = res.data.filter((el) => el.type === 'EGRUL_VALIEV')[0];
          this.ustavObject = res.data.filter((el) => el.type === 'USTAV_VALIEV')[0];
          this.loading = false;
          console.log('getEgrulAndUstav успех');
        }
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },

    async egrulPost() {
      const formData = new FormData();
      formData.append('file', this.egrulFile);
      if (this.egrulFile.type === "application/pdf") {
        try {
          const res = await axios.post(`/api/2024/file/scan/upload/egrul/valiev`, formData, {
            headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
          });
          if (res.data) {
            await this.getEgrulAndUstav();
            this.loading = false;
            console.log('egrulPost успех');
          }
        } catch (e) {
          this.loading = false;
          console.log(e);
        }
      } else {
        alert(`файл должен соответствовать формату pdf`)
      }
    },

    async ustavPost() {
      const formData = new FormData();
      formData.append('file', this.ustavFile);
      if (this.ustavFile.type === "application/pdf") {
        try {
          const res = await axios.post(`/api/2024/file/scan/upload/ustav/valiev`, formData, {
            headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
          });
          if (res.data) {
            await this.getEgrulAndUstav();
            this.loading = false;
            console.log('ustavPost успех');
          }
        } catch (e) {
          this.loading = false;
          console.log(e);
        }
      } else {
        alert(`файл должен соответствовать формату pdf`)
      }
    },

    async filePost(fileType, file) {
      const formData = new FormData();
      formData.append('fileType', fileType);
      formData.append('file', file);
      formData.append('rowId', this.docs.rowID);
      if (file.type === "application/pdf") {
        try {
          const res = await axios.post(`/api/2024/valiev/upload/${fileType}`, formData, {
            headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
          });
          if (res.data) {
            if (fileType === 'FILE_CHARACTER_1') {
              this.docs.character1 = res.data.character1;
            } else if (fileType === 'FILE_CHARACTER_2') {
              this.docs.character2 = res.data.character2;
            } else if (fileType === 'FILE_SOGL') {
              this.docs.approval = res.data.approval;
            } else if (fileType === 'FILE_SOVET') {
              this.docs.councilDecision = res.data.councilDecision;
            } else if (fileType === 'FILE_COPY_DIPLOM') {
              this.docs.prevDiplomaCopy = res.data.copyDiplom;
            } else if (fileType === 'FILE_USPEH') {
              this.docs.academicPerformance = res.data.academicPerformance;
            } else if (fileType === 'FILE_OLD_FIO') {
              this.docs.oldFioObject = res.data.oldFio;
            }

            await this.getAll();
            this.loading = false;
            this.character1File = null;
            this.character2File = null;
            this.approvalFile = null;
            this.councilDecisionFile = null;
            this.academicPerformanceFile = null;
            this.prevDiplomaCopyFile = null;
            this.oldFioFile = null;
            console.log('filePost успех');
          }
        } catch (e) {
          this.loading = false;
          console.log(e);
        }
      } else {
        alert(`файл должен соответствовать формату pdf`)
      }

    },

    async getFile(id, name, ext) {
      let uri = `/api/2024/file/scan/download?file=${id}`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`}
      })
      this.saveByteArray(`${name}.${ext}`, responseAxios.data, ext)
    },

    saveByteArray(reportName, byte, ext) {
      let blob = new Blob([byte], {type: `application/${ext}`});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.target = '_blank'; // вариант открытия в новом окне
      // link.download = reportName; // вариант скачивания
      link.click();
    },

    async deleteFile(fileType) {
      // fileType - костыль на визуальное удаление файлов налету
      this.loading = true;
      let uri = `/api/2024/valiev/delete/${fileType}?rowId=${this.docs.rowID}`;
      try {
        const res = await axios.delete(uri, {
          headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
        });
        if (res.status === 200 && res.data) {
          if (fileType === 'FILE_CHARACTER_1') {
            this.docs.character1 = null;
          } else if (fileType === 'FILE_CHARACTER_2') {
            this.docs.character2 = null;
          } else if (fileType === 'FILE_SOGL') {
            this.docs.approval = null;
          } else if (fileType === 'FILE_SOVET') {
            this.docs.councilDecision = null;
          } else if (fileType === 'FILE_COPY_DIPLOM') {
            this.docs.prevDiplomaCopy = null;
          } else if (fileType === 'FILE_USPEH') {
            this.docs.academicPerformance = null;
          } else if (fileType === 'FILE_OLD_FIO') {
            this.docs.oldFioObject = null;
          } /*else if (fileType === 'USTAV') {
            this.ustavObject = null;
          } else if (fileType === 'EGRUL') {
            this.egrulObject = null;
          }*/

          // await this.getEgrulAndUstav();
          await this.getAll();
          this.loading = false;
          console.log('deleteFile успех');
        }
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },

    async deleteOrgFile(ID, fileType) {
      this.loading = true;
      let uri = `/api/2024/file/scan/delete?fileId=${ID}`;
      try {
        const res = await axios.delete(uri, {
          headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
        });
        if (res.status === 200 && res.data) {
          if (fileType === 'USTAV_VALIEV') {
            this.ustavObject = null;
          } else if (fileType === 'EGRUL_VALIEV') {
            this.egrulObject = null;
          }

          await this.getEgrulAndUstav();
          // await this.getAll();
          this.loading = false;
          console.log('deleteOrgFile успех');
        }
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },

    async addRow() {
      await this.$router.push({name: 'ApplicantFormValiev2024', params: {rowID: '0'}});
      this.loading = true;
    },

    async editRow(rowId) {
      await this.$router.push({name: 'ApplicantFormValiev2024', params: {rowID: `${rowId}`}});
      this.loading = true;
    },

    deleteConfirmFunc(rowID) {
      this.deleteConfirmDialog = true;
      this.deleteRowID = rowID;
    },

    async deleteRow() {
      this.loading = true;
      try {
        const res = await axios.delete(`/api/2024/valiev/delete?rowId=${this.deleteRowID}`, {
          headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
        });
        if (res.status === 200 && res.data) {
          console.log('deleteRow успех');
          await this.getAll();
          this.deleteRowID = null;
          this.deleteConfirmDialog = false;
          this.loading = false;
        }
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },

    async getContacts() {
      let uri = '';
      if (this.getFakeUser) {
        uri = `/api/cms/contacts/get?groupId=${this.getFakeUser.id}&groupName=orgID`;
      } else {
        uri = `/api/cms/contacts/get`;
      }
      const res = await request({
        endpoint: uri,
        method: 'get',
        token: `${this.getAuthData.token}`,
      });
      if (res.status === 200 && res.data) {
        this.contacts = res.data;
      }
    },

    async getInventory(ID) {
      let uri = `/api/2024/reports/valiev/inventory/${ID}`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
      });
      this.saveByteArray(`Report.pdf`, responseAxios.data, 'pdf');
    },

    async getCheckList(ID) {
      // print
      let uri = `/api/2024/reports/valiev/check_list/${ID}`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
      });
      this.saveByteArray(`Report.pdf`, responseAxios.data, 'pdf');
    },

    async getAppendix1(ID) {
      // print
      let uri = `/api/2024/reports/valiev/appendix_1/${ID}`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
      });
      this.saveByteArray(`Report.pdf`, responseAxios.data, 'pdf');
    },

    async getAppendix2(ID) {
      // print
      let uri = `/api/2024/reports/valiev/appendix_2/${ID}`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
      });
      this.saveByteArray(`Report.pdf`, responseAxios.data, 'pdf');
    },

    async applicantDocsDialogOpen(row) {
      this.docs.row = row;

      if (
        row.mainEdit2022OldFirstName ||
        row.mainEdit2022OldLastName ||
        row.mainEdit2022OldSecondName
      ) {
        this.docs.oldFioRow = true;
      } else {
        this.docs.oldFioRow = false;
      }

      this.character1File = null;
      this.character2File = null;
      this.approvalFile = null;
      this.councilDecisionFile = null;
      this.academicPerformanceFile = null;
      this.prevDiplomaCopyFile = null;
      this.oldFioFile = null;

      this.docs.rowID = row.id;
      this.docs.approval = row.approval;
      this.docs.character1 = row.character1;
      this.docs.character2 = row.character2;
      this.docs.councilDecision = row.councilDecision;
      this.docs.academicPerformance = row.academicPerformance;
      this.docs.prevDiplomaCopy = row.copyDiplom;
      this.docs.oldFioObject = row.oldFio;
      this.docs.fio = `${row.mainLastName} ${row.mainFirstName} ${row.mainSecondName}`;
      this.docs.info = `${row.mainEducationStatus.educationStatus} • ${row.mainFo.name} • ${row.mainCourse} курс/год обучения`;
      this.applicantDocsDialog = true;
      console.log('applicantDocsDialogOpen');
    },

    async returnToAdmin() {
      await this.clearFakeUser();
      await this.$router.push('/info');
      await this.$router.push('/valiev-2024/applicants');
    },
  },
};
</script>

<style lang="scss">
.error_text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;

  p {
    color: red;
    font-size: 1.7rem;
  }
}

.colorLabel {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  margin-left: 24px;
  margin-bottom: 2px;
}

.rowOrder {
  margin-top: 1px;
  margin-left: 24px;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  color: #89a0b0 !important;
}

.router_btn {
  height: 26px !important;
  padding: 0 8px !important;
  margin-bottom: 15px;
  width: 100px;
  cursor: url(../../../assets/icons/cursor.svg), auto;
}
</style>
