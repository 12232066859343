<template>
  <AdminLK v-if="isManager && !getFakeUser"/>
  <ApplicantsValiev v-else-if="(isManager && getFakeUser) || !isExpert && !isTest"/>
</template>

<script>
import {mapGetters} from "vuex";
import ApplicantsValiev from "@/2024/components/applicants/ApplicantsValiev";
import AdminLK from "@/2024/components/applicants/admin/valiev/AdminLK";

export default {
  name: "ApplicantsValievView",
  components: {AdminLK, ApplicantsValiev},
  computed: {
    ...mapGetters('keycloak', {
      getFakeUser: 'getFakeUser',
      isManager: 'isManager',
      isExpert: 'isExpert',
      isTest: "isTest"
    }),
  },
  created() {
    if (this.isExpert && !this.isManager) {
      this.$router.push({name: 'ApplicantsSelectionValiev2024'});
    }
  }
}
</script>

<style scoped>

</style>